$color-main-text: #212427;
$color-secondary-text: #7C7C7C;
$color-alternative-text: #FFFFFF;
$color-subheading-text: #8180A0;


$color-primary-button: #1D70B8;

$color-main-background: #FFFFFF;
$color-alternative-background: #e9e7ed;

$color-border: black;

$color-thematic-break: #D6E0E5;

$color-background-color: #EEF2F6;

$color-gov-link: #1D70B8;

$color-footer: #04043F;

$color-completed: #0D8D98;

$color-focus: #ffbf47;

$color-quotes: #EEF2F6;

$color-quotes-icons: #212427;

$color-delete: #D4351C;
$color-visited: #4C2C92;
$color-tab: #dde5ed;
$color-info-box: #E0E8EF;
