
// ARTICLE LIST PAGE
// =================

@import 'partials/colours';


.article-list-page {

  .number-display {
    margin-left: 10px;
  }

  .information-banner {
    background-color: $white;
    border: solid $stone 2px;
  }

  .list-teaser {
    padding: 2em 0;
    line-height: 1.5;
  }

  .error-reporting {
    padding-top: 30px;
  }

  .card-inner {
    padding: 30px;
  }
}
