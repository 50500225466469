$great-design-system-white: #fff;
$great-design-system-grey-border: #7d8e96;
$great-design-system-hover-primary-button: #003078;
$great-design-system-interface-mono-50: #7d8e96;
$great-design-system-govuk-light-grey: #f3f2f1;
$great-design-system-border-grey: #d9d9d9;
$great-design-system-purple: #a24a94;
$great-design-system-border-thin: #b4c7d9;
$great-design-system-govuk-yellow-highlight: #fd0;
$great-design-system-govuk-off-black: #0b0c0c;
$great-design-system-govuk-blue: #1d70b8;
$great-design-system-light-blue: #eef2f6;
$great-design-system-mint-green: #8ce2d0;

.great-ds-border-bottom-grey {
  border-bottom: 1px solid $great-design-system-grey-border;
}

.great-ds-heading-link {
  &::after {
    top: 0.12rem;
  }
}

.great-ds-sign-up-promo {
  @include govuk-media-query($until: tablet) {
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
  }

  ul > li {
    display: flex;
  }
}

.great-ds-contact-us-promo {
  display: flex;

  @include govuk-media-query($until: tablet) {
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
    flex-direction: column;
    margin: -30px 0 -40px;
  }

  > * {
    flex-basis: fit-content;
  }
}

.great-ds-button-primary {
  border-bottom: 3px solid $great-design-system-hover-primary-button;

  > span {
    display: inline-block;
    position: relative;
    top: 3px;
    margin-left: 5px;

    @include govuk-media-query($until: tablet) {
      top: 2px;
    }
  }

  &:hover,
  &:focus {
    path {
      fill: $great-design-system-govuk-blue;
    }
  }
}

.great-ds-text-grey {
  color: $great-design-system-interface-mono-50;
}

.great-ds-details {
  position: relative;

  summary {
    &::-webkit-details-marker {
      display: none;
    }

    display: block;
    margin-bottom: 0;

    &:hover {
      background-color: $great-design-system-govuk-light-grey;

      .govuk-accordion-nav__chevron {
        color: $great-design-system-govuk-off-black;
        background: $great-design-system-govuk-off-black;

        &::after {
          color: $great-design-system-govuk-light-grey;
        }
      }

      .govuk-accordion__section-toggle-text {
        color: $great-design-system-govuk-off-black;
      }
    }

    &:focus {
      outline: 0;

      .great-ds-details__status {
        outline: 3px solid rgba(0, 0, 0, 0);
        color: #212427;
        background-color: $great-design-system-govuk-yellow-highlight;
        box-shadow: 0 -2px $great-design-system-govuk-yellow-highlight,
          0 4px $great-design-system-govuk-off-black;
        text-decoration: none;
      }

      .govuk-accordion-nav__chevron {
        color: $great-design-system-govuk-off-black;
        background: $great-design-system-govuk-off-black;

        &::after {
          color: $great-design-system-govuk-light-grey;
        }
      }

      .govuk-accordion__section-toggle-text {
        color: $great-design-system-govuk-off-black;
      }
    }
  }

  .great-ds-details__status {
    display: inline-block;
    font-weight: 600;
  }

  .great-ds-details__status-text--open {
    display: none;
  }

  &[open] {
    summary {
      @include govuk-media-query($from: tablet) {
        margin-bottom: -10px;
      }

      ~ p {
        background: $great-design-system-white;
        position: relative;
      }
    }

    .great-ds-details__status-text--open {
      display: inline-block;
    }

    .great-ds-details__status-text--closed {
      display: none;
    }

    .govuk-accordion-nav__chevron {
      transform: rotate(0deg);
    }
  }
}

.great-ds-details--guided-journey {
  &[open] {
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
}

.great-ds-guided-journey-graphic {
  display: none;
  position: absolute;

  @media screen and (min-width: 1001px) {
    display: block;
    width: 470px;
    left: 570px;
    top: -50px;
  }

  @media screen and (min-width: 1101px) {
    width: 520px;
    left: 630px;
    top: -70px;
  }

  @media screen and (min-width: 1201px) {
    width: 570px;
    left: 680px;
    top: -120px;
  }

  @media screen and (min-width: 1301px) {
    width: 600px;
    left: 720px;
    top: -140px;
  }

  @media screen and (min-width: 1401px) {
    width: 600px;
    left: 740px;
    top: -140px;
  }

  @media screen and (min-width: 1501px) {
    width: 600px;
    left: 740px;
    top: -140px;
  }
}

.great-ds-guided-journey-homepage {
  background: $great-design-system-light-blue;

  h2.govuk-heading-xl {
    @include govuk-media-query($until: tablet) {
      line-height: 40px;
    }
  }

  button[type='submit'] {
    @include govuk-media-query($until: tablet) {
      width: auto;
    }
  }

  label {
    @include govuk-media-query($until: tablet) {
      font-size: 16px;
    }
  }
}

.great-ds-guided-journey-homepage__divider {
  @include govuk-media-query($until: tablet) {
    padding: 40px 15px 0;
    margin: 0 -15px;
  }
}

.great-ds-link {
  display: inline-flex;
  text-decoration: none;

  &.govuk-link {
    text-decoration: none;
  }

  i {
    font-size: 1.5rem;
    margin-right: 10px;

    @include govuk-media-query($from: tablet) {
      font-size: 2rem;
    }
  }

  span {
    font-weight: 600;
    text-decoration: underline;
  }

  &:focus {
    span {
      text-decoration: none;
    }
  }
}

.great-ds-link--inverted {
  i {
    position: relative;

    &::before {
      z-index: 1;
      position: relative;
    }

    &::after {
      content: '';
      background: $great-design-system-white;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 100px;

      @include govuk-media-query($from: tablet) {
        width: 28px;
        height: 28px;
      }
    }
  }

  span {
    color: $great-design-system-white;
  }

  &:focus {
    span {
      color: $great-design-system-govuk-off-black;
    }
  }

  &:active:focus {
    span {
      color: $great-design-system-white;
    }
  }
}

.great-ds-hr {
  background: $great-design-system-border-grey;
  border: 0;
  display: block;
  height: 4px;
  width: 100%;
}

.great-ds-box-shadow {
  box-shadow: 0px 4px 10px 0px rgba(4, 4, 63, 0.1);
}

.great-ds-autocomplete-input.govuk-input {
  width: 100%;
  padding-right: 40px;

  &::placeholder {
    color: $great-grey;
    font-size: 14px;

    @include govuk-media-query($from: tablet) {
      font-size: 16px;
    }
  }
}

.great-ds-autocomplete-input__wrapper {
  position: relative;

  &::before {
    display: inline-block;
    height: 18px;
    width: 18px;
    background-color: $great-design-system-white;
    background-image: url(/static/images/search-black.svg);
    background-size: 18px;
    top: 13px;
    left: calc(100% - 30px);
    position: absolute;

    @include govuk-media-query($from: tablet) {
      top: 15px;
    }
  }

  button {
    cursor: pointer;
    display: none;
    position: absolute;
    border: none;
    background: $great-design-system-white;
    width: 30px;
    height: 30px;
    top: 7px;
    right: 10px;
    z-index: 1;

    @include govuk-media-query($from: tablet) {
      top: 10px;
    }
  }
}

.js-enabled .great-ds-autocomplete-input__wrapper {
  &::before {
    content: '';
  }
}

.great-ds-widget-container {
  position: relative;

  .great-ds-edit-control {
    background: $great-design-system-white;
    position: absolute;
    top: 20px;
    right: 30px;
    text-decoration: none;

    @include govuk-media-query($from: tablet) {
      top: 30px;
    }

    span {
      margin-right: 10px;
      text-decoration: underline;
    }
  }

  .great-ds-skip-control {
    span {
      line-height: 50px;
    }

    @include govuk-media-query($from: tablet) {
      margin-left: 30px;
    }
  }

  .great-checkbox .govuk-checkboxes__label:before {
    border-radius: 0;
  }
}

.great-ds-widget-info-box {
  display: flex;
  gap: 10px;

  @include govuk-media-query($from: tablet) {
    gap: 20px;
  }

  > i {
    color: $great-design-system-govuk-blue;
    font-size: 1.5rem;

    @include govuk-media-query($from: tablet) {
      font-size: 2rem;
      gap: 20px;
    }
  }
}

.great-ds-purple-text {
  color: $great-design-system-purple !important;

  @include govuk-media-query($until: tablet) {
    line-height: 24px;
  }
}

.great-ds-card-meta-label {
  color: $great-design-system-purple !important;
  display: flex;
  gap: 10px;
  font-size: 16px;

  @include govuk-media-query($from: desktop) {
    position: absolute;
    top: 15px;
    right: 0;
  }
}

.great-ds-market-and-flag {
  display: inline-flex !important;
  gap: 20px;

  img {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    width: 40px;
  }
}

.great-ds-market-widget {
  @include govuk-media-query($from: desktop) {
    display: flex;
  }

  .great-ds-market-widget__col {
    &:first-of-type {
      @include govuk-media-query($from: desktop) {
        flex-basis: 66.666%;
        padding-right: 60px;
      }
    }

    &:last-of-type {
      @include govuk-media-query($from: desktop) {
        flex-basis: 33.333%;
      }
    }
  }

  .great-ds-market-widget__image {
    @include govuk-media-query($from: tablet, $until: desktop) {
      margin-top: 30px;
    }
  }
}

.great-ds-category-panels {
  display: flex;
  flex-direction: column;

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  & > a {
    background: $great-design-system-white;
    border: 2px solid $great-design-system-border-thin;
    box-shadow: 0px 4px 10px 0px rgba(4, 4, 63, 0.1);
    color: $great-design-system-govuk-blue;
    font-weight: 600;
    flex-basis: calc(100% - 10px);
    line-height: 25px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    position: relative;

    &:hover {
      border: 2px solid $great-design-system-white;
      box-shadow: 0px 4px 10px 0px rgba(4, 4, 63, 0.3);
      text-decoration: underline;

      &::before {
        content: '';
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: -4px;
        left: -4px;
        display: block;
        background: $great-design-system-white;
        border-radius: 10px;
        border: 2px solid $great-design-system-border-thin;
        z-index: 0;
      }

      span,
      svg {
        z-index: 1;
      }
    }

    &:active:hover,
    &:focus:hover {
      background: $great-design-system-white;
      text-decoration: none;

      span {
        text-decoration: none;
      }
    }

    &:focus {
      outline: 0;

      span {
        outline: 3px solid rgba(0, 0, 0, 0);
        color: #212427 !important;
        background-color: govuk-colour('yellow');
        box-shadow: 0 -2px govuk-colour('yellow'), 0 4px #0b0c0c;
        text-decoration: none;
      }
    }

    > span {
      flex-basis: auto;
    }

    > svg {
      width: 60px;
      min-width: 60px;
      height: auto;
    }

    @include govuk-media-query($from: tablet) {
      flex-basis: calc(50% - 10px);
      margin-bottom: 0;
    }
  }
}

.great-ds-category-panels--landing {
  gap: 15px;
  margin: 0;

  @media screen and (min-width: 851px) {
    gap: 30px;
  }

  > a {
    gap: 10px;
    margin-bottom: 0;

    @include govuk-media-query($from: tablet) {
      flex-basis: calc(50% - 20px);
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }

    @media screen and (min-width: 851px) {
      flex-basis: calc(33.333% - 20px);
    }
  }
}

.great-ds-category-panels--homepage {
  gap: 15px;

  @media screen and (min-width: 851px) {
    gap: 30px;
  }

  > a {
    gap: 10px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    @include govuk-media-query($from: tablet) {
      flex-direction: column;
      align-items: flex-start;
      flex-basis: calc(50% - 15px);
      gap: 10px;
      padding: 15px;
    }

    @media screen and (min-width: 851px) {
      flex-basis: calc(25% - 22.5px);
    }

    @media only screen and (min-width: 961px) {
      padding: 15px 30px 20px;
    }
  }
}

.great-ds-card-panels {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  flex-direction: column;

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
    gap: 20px;
  }

  > .great-ds-card-panels__item {
    flex-basis: calc(50% - 10px);

    &:only-child {
      flex-basis: 100%;
    }
  }

  > .great-ds-card-panels__item--full-width {
    flex-basis: 100%;
  }
}

.great-ds-card-panels--restricted {
  gap: 5px;

  > .great-ds-card-panels__item {
    flex-basis: calc(50% - 2.5px);
  }
}

.great-ds-side-note {
  @media only screen and (min-width: 1250px) {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 35%;
  }
}

.great-ds-info-pane {
  display: flex;
  gap: 20px;
  flex-direction: column;

  i {
    color: $great-design-system-govuk-blue;
    font-size: 36px;
  }

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
  }
}

.great-ds-make-or-do {
  position: relative;
  overflow: initial;
  overflow-x: clip;

  img {
    display: none;

    @media only screen and (min-width: 1300px) {
      display: block;
      position: absolute;
      bottom: 0;
      right: -35%;
      height: 100%;
    }
  }
}

.great-ds-make-or-do--homepage {
  img {
    display: none;

    @media only screen and (min-width: 900px) {
      display: block;
      position: absolute;
      bottom: 0;
      right: -35%;
      height: 100%;
    }

    @media only screen and (min-width: 950px) {
      right: -30%;
    }

    @media only screen and (min-width: 1100px) {
      right: -15%;
    }

    @media only screen and (min-width: 1200px) {
      right: -5%;
    }

    @media only screen and (min-width: 1300px) {
      right: 0;
    }
  }
}

.bgs-hero {
  background-position: bottom;
  background-repeat: no-repeat;

  @include govuk-media-query($until: tablet) {
    background-size: contain;
  }
}

.bgs-guide-hero {
  background-size: cover;
  width: 100%;
}

.bgs-about--image {
  img {
    max-width: 100%;
    height: auto;
  }
}

.bgs-email-guide {
  background: url(/static/images/send-mail-graphic.svg);
  background-repeat: no-repeat;
  background-position: 90% 0;
}

.bgs-box-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.bgs-section-logo {
  background-repeat: no-repeat;
  background-position: 95% 5%;
}

.bgs-section-logo--yorkshire {
  background-image: url('/static/images/yorkshire.svg');
  background-size: 225px;
  background-position: 100% -82px;
}

.bgs-section-logo--west-midlands {
  background-image: url('/static/images/west-midlands.svg');
  background-size: 225px;
  background-position: 100% -118px;
}

.bgs-section-logo--east-of-england {
  background-image: url('/static/images/east-of-england.svg');
  background-size: 225px;
  background-position: 100% -141px;
}

.bgs-section-logo--south-east {
  background-image: url('/static/images/south-east.svg');
  background-size: 225px;
  background-position: 100% -172px;
}

.bgs-section-logo--south-west {
  background-image: url('/static/images/south-west.svg');
  background-size: 225px;
  background-position: 100% -172px;
}

.bgs-section-logo--scotland {
  background-image: url('/static/images/scotland.svg');
  background-size: 259px;
  background-position: 109% -48px;
}

.bgs-section-logo--northern-ireland {
  background-image: url('/static/images/northern-ireland.svg');
  background-size: 251px;
  background-position: 109% -98px;
}

.bgs-section-logo--east-midlands {
  background-image: url('/static/images/east-midlands.svg');
  background-size: 225px;
  background-position: 100% -120px;
}

.bgs-section-logo--london {
  background-image: url('/static/images/london.svg');
  background-size: 225px;
  background-position: 100% -166px;
}

.bgs-section-logo--north-west {
  background-image: url('/static/images/north-west.svg');
  background-size: 225px;
  background-position: 100% -91px;
}

.bgs-section-logo--north-east {
  background-image: url('/static/images/north-east.svg');
  background-size: 225px;
  background-position: 100% -71px;
}

.bgs-section-logo--wales {
  background-image: url('/static/images/wales.svg');
  background-size: 225px;
  background-position: 100% -159px;
}

.great-ds-address {
  font-style: normal;

  .great-ds-address__row {
    display: flex;
    align-items: anchor-center;
    padding-bottom: 10px;

    > *:first-child {
      font-weight: bold;
      min-width: 105px;
    }
  }
}

.great-ds-border-top-blue-3 {
  border-top: 3px solid #1d70b8 !important;
}

.great-ds-card--no-border .great-ds-card__inner {
  border-bottom: 0 !important;
}

.great-ds-max-width-70 {
  max-width: 70%;
}
