@import '../colors/colors.scss';
@import '../fonts/main.scss';

.great-signup {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background: #f5f5f6;

    &__logo {
        width: 148px;
        height: auto;

        @media (max-width: 768px) {
            max-width: 132px;
        }
    }

    &__form-panel {
        color: #4c436e;
        min-height: 100vh;
        padding: 40px 105px 0px 105px;
        background-color: #fff;
        width: 610px;
        flex-shrink: 0;
        z-index: 1;

        @media screen and (max-width: 1024px) {
            padding: 30px;
            width: 40%;
        }

        @media screen and (max-width: 768px) {
            padding: 20px;
            width: 50%;
        }

        @media screen and (max-width: 640px) {
            width: 100%;
            padding: 30px 30px;
        }
    }

    &__form {

        .vertical-seperator {
            margin-top: 20px;
            margin-bottom: 20px;

            span {
                display: inline-block;
                font-size: 19px;
                width: 20%;
                text-align: center;
                margin: 0;
            }

            hr {
                display: inline-block;
                height: 2px;
                margin: 0;
                vertical-align: middle;
                width: 40%;
            }
        }
    }

    &__info-panel {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
        position: relative;

        @media screen and (max-width: 640px) {
            display: none;
        }


        &__content {
            max-width: 850px;
            padding: 0 40px;
            z-index: 1;
            text-align: center;
        }

        &__heading {
            color: #fff;
            font-size: 56px;
            line-height: 52px;
            text-align: center;
            margin-bottom: 0;

            @media screen and (max-width: 1024px) {
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 30px;
            }
        }

        &__subheadings {
            margin-top: 30px;
            text-align: left;

            p {
                align-items: center;
                margin-bottom: 45px;
                font-size: 24px;
                line-height: 32px;

                @media screen and (max-width: 1024px) {
                    font-size: 24px;
                    line-height: 34px;
                }

                i {
                    margin-right: 10px;
                    font-size: 1.5em;
                }
            }
        }

        &__confirmation {
            .fa-info-circle {
                font-size: 40px;
            }

            .form-label {
                font-size: 19px;
            }
        }
    }
}
