@import '../fonts/typography.scss';

figure.great-blockquote {
  padding: 0;
  margin: 0;

  blockquote {
    display: flex;
    gap: 10px;
    margin: 0;
    position: relative;
    background-color: transparent;

    &:before {
      content: open-quote;
      font-size: 70px;
      $font-family: $header-font;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: close-quote;
      font-size: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      font-size: 24px;
      margin: 0;
      display: inline;
      margin-left: 30px;
    }
  }
}

.great-full-width-header-image {
  height: 200px;
  background-position: 50%;
  background-size: cover;

  @include govuk-media-query($from: tablet) {
    height: 345px;
  }
}

.great-floating-right-column {
  @include govuk-media-query($from: tablet) {
    position: relative;
  }

  > div:last-of-type {
    @include govuk-media-query($from: tablet) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.great-filters {
  &.filters {
    > p {
      @include govuk-media-query($from: tablet) {
        margin-bottom: 10px;
      }
    }

    label {
      font-size: 20px;
      line-height: 20px;

      &.filter-section-headings {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    .filter-section-active {
      display: block;
    }

    &.mobile-filters {
      > p {
        @include govuk-media-query($until: tablet) {
          margin-bottom: 10px;
        }

        display: block;
      }

      p,
      label {
        font-size: 24px;
      }

      .multiple-choice {
        margin-bottom: 12px;

        label {
          @include govuk-media-query($until: tablet) {
            font-size: 20px;
            padding-left: 10px;
          }
        }

        [type='radio'] + label {
          &:before {
            width: 30px;
            height: 30px;
          }

          &:after {
            top: 7px;
            left: 7px;
          }
        }
      }

      .update {
        font-size: 16px;
      }
    }

    .mobile-filter-toggle {
      @include govuk-media-query($until: tablet) {
        font-size: 16px;
      }
    }

    .filter-section {
      > label {
        margin-bottom: 0;
        padding: 9px 5px 8px 2px;

        &.arrows-left {
          &:before {
            transform: rotate(225deg);
            margin: 3px 10px 0 0;
          }
        }

        &.arrows-left-active {
          &:before {
            transform: rotate(45deg);
            margin: 3px 10px 0 0;
          }
        }
      }

      .options {
        overflow-y: auto;
      }

      &:last-child {
        border-bottom: 1px solid $great-dark-grey;
      }
    }

    .filter-section-toggle {
      &:focus {
        ~ label {
          outline: 3px solid $great-yellow;
          outline-offset: 0;
        }
      }
    }

    &.event-filters {
      min-width: 150px;
    }
  }
}

.great-card {
  @include govuk-media-query($until: tablet) {
    margin-bottom: 30px;
  }

  a {
    background: $great-white;
    border-bottom: 4px solid rgba(0, 0, 0, 0.2);
    display: block;
    text-decoration: none;
    position: relative;
    box-sizing: border-box;

    .great-card__link--underline {
      text-decoration: underline;
      text-underline-offset: 0.1rem;
    }

    .great-card__link--icon::before {
      color: #212427 !important;
    }

    &:visited {
      .great-card__link {
        color: $govuk-link-visited-colour;
      }
    }

    &:hover {
      border-bottom: 4px solid rgba(0, 0, 0, 0.3);

      .great-card__link {
        text-decoration-line: underline;
        text-decoration-thickness: 3px;
        color: #003078 !important;
        text-underline-position: under;

        --webkit-text-underline-offset: 0.05rem;

        @include govuk-media-query($from: tablet) {
          --webkit-text-underline-offset: 0.1rem;
        }
      }
    }

    &:focus {
      border-bottom: 4px solid rgba(0, 0, 0, 0.3);
      margin: 0;
      outline: none;

      .great-card__link {
        color: #212427;
        text-decoration: none;

        span {
          outline: 3px solid rgba(0, 0, 0, 0);
          color: #212427;
          background-color: govuk-colour('yellow');
          box-shadow: 0 -2px govuk-colour('yellow'), 0 4px #0b0c0c;
          text-decoration: none;
        }
      }
    }

    &:active {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: none;
      padding-top: 3px;

      @include govuk-media-query($from: tablet) {
        height: calc(100% - 3px);
        margin-top: 3px;
        padding-top: 0;
      }
    }

    &:focus:active {
      background: $great-white;
    }
  }

  img,
  svg {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }

  h4 {
    font-size: 28px;
  }

  .govuk-caption {
    display: block;
    font-size: 16px;

    @include govuk-media-query($from: tablet) {
      min-height: 22px;
    }
  }

  .great-card__link {
    color: $govuk-brand-colour;
    display: inline-block;
    text-decoration: underline;
    max-width: 80%;
    text-underline-position: under;
    text-decoration-thickness: max(1px, 0.0625rem);

    --webkit-text-underline-offset: 0.05rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include govuk-media-query($from: tablet) {
      --webkit-text-underline-offset: 0.1rem;
    }
  }

  .great-card__link--heading {
    &::after {
      content: '\f061';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      display: inline-block;
      pointer-events: none;
      top: 12px;
      right: 0;
    }

    &.great-card__link--external {
      &::after {
        content: '\f35d';
      }
    }
  }

  .great-card__link--heading--18 {
    font-size: 18px !important;
  }

  .great-card__tag {
    @extend .govuk-body-s;
    position: absolute;
    bottom: 20px;
  }

  .great-card__link--chevron {
    position: relative;
    padding-right: 25px;

    &::after {
      background-image: url(/static/icons/internal-link.svg);
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: 50%;
      position: absolute;
      display: inline-block;
      content: '';
      pointer-events: none;
      height: 20px;
      width: 26px;
      bottom: 4px;
      right: 2px;
    }
  }
}

.great-card--homepage {
  height: auto;

  @include govuk-media-query($until: tablet) {
    margin-bottom: 0;
  }

  a {
    display: flex;
    height: auto;
  }

  img,
  div > svg {
    width: 50px;
    margin-left: 10px;
    margin-top: 5px;

    @include govuk-media-query($from: tablet) {
      width: 80px;
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  .great-card__svg-wrapper {
    ~ div {
      width: 100%;
    }
  }

  .great-card__link--heading {
    max-width: 85%;
  }
}

.great-card--undecorated {
  a {
    &:hover,
    &:focus {
      .great-card__link--heading {
        &::after {
          content: '';
        }
      }
    }
  }
  .great-card__link--heading {
    text-decoration: none;

    &::after {
      content: '';
    }
  }
}

.great-card-thin-border-only-focus-text {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  &:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    .great-card__link {
      color: $govuk-brand-colour !important;
    }
  }
}

.great-card--no-active-jump {
  a {
    &:active {
      height: auto;
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.great-card--dep-landing {
  @include govuk-media-query($until: tablet) {
    margin-bottom: 0;
  }

  svg {
    max-width: 80px;
    padding-left: 20px;
    margin-bottom: -20px;
  }
}

.great-dep-card-accessible-focus {
  &:focus {
    outline: 0;

    .govuk-link {
      outline: 3px solid rgba(0, 0, 0, 0);
      color: #212427 !important;
      background-color: govuk-colour('yellow');
      box-shadow: 0 -2px govuk-colour('yellow'), 0 4px #0b0c0c;
      text-decoration: none;
    }
  }
}

.great-card--cta {
  @include govuk-media-query($until: tablet) {
    margin-bottom: 30px;
  }

  display: flex;

  a {
    background-color: $great-light-blue;

    &:focus:active {
      background-color: $great-light-blue !important;
    }
  }

  .great-card__tag {
    img {
      width: 16px;
      display: inline-block;
      margin-right: 12px;
    }
  }
}

.great-card--cta-no-bg {
  a {
    background-color: transparent;

    &:active,
    &:focus:active {
      height: 100%;
      outline: none;
      background-color: transparent !important;
      border-bottom: 4px solid rgba(0, 0, 0, 0.2);
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.great-card--cta-with-content {
  .great-card__tag {
    bottom: 0 !important;
  }
  &:after {
    bottom: 0 !important;
  }
}

.great-card--cta-with-border {
  border-top: 4px solid $great-purple !important;
}

.great-card--homepage-container {
  margin-bottom: 14px;

  @include govuk-media-query($from: tablet) {
    flex-basis: 50%;
    margin-bottom: 19px;
  }

  @media (min-width: 1024px) {
    flex-basis: 33.333%;
  }
}

.great-card-row {
  @include govuk-media-query($from: tablet) {
    display: flex;
    flex-flow: wrap;
  }

  .great-card {
    @include govuk-media-query($from: tablet) {
      height: 100%;
    }

    a:not(.great-card-row .great-card a:active) {
      @include govuk-media-query($from: tablet) {
        height: 100%;
      }
    }
  }
}

.great-hr-blue {
  background: $great-hr-blue;
  border: 0;
  display: block;
  height: 4px;
  width: 100%;
}

.great-hr-blue--thin {
  height: 2px;
}

.great-hr-purple {
  border-top: 4px solid $great-purple !important;
  display: block;
  width: 100%;
}

.great-hr-purple--thin {
  border-top: 3px solid $great-purple !important;
}

.great-badge {
  background: #cce2d8;
  color: #005a30;
  display: inline-flex !important;
  align-items: center;
  font-size: 16px;
  font-family: $great-font-bold;
  padding: 0 10px;
  height: 25px;
  width: fit-content;
  text-transform: uppercase;

  &.closed {
    background: #fff7bf;
    color: #594d00;
  }

  &.ended {
    color: #383f43;
    background: #eeefef;
  }
}

.great-info-text {
  @include govuk-media-query($until: tablet) {
    display: flex;
  }

  i {
    @include govuk-media-query($until: tablet) {
      margin-top: 2px;
    }

    position: relative;
    bottom: -3px;
    margin-right: 10px;
  }

  span {
    @include govuk-media-query($until: tablet) {
      line-height: 1;
    }
  }
}

.great-time-and-duration {
  @include govuk-media-query($from: tablet) {
    display: flex;
  }

  span {
    display: block;

    @include govuk-media-query($from: tablet) {
      display: list-item;
      margin-left: 25px;
    }
  }
}

.great-text-input,
.great-text-input.govuk-input {
  height: 44px;
  padding-left: 15px;

  &::placeholder {
    color: $great-dark-grey;
  }

  @include govuk-media-query($from: tablet) {
    height: 50px;
  }
}

.great-text-input--search,
.great-text-select--arrow {
  &::after {
    display: inline-block;
    content: '';
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: initial;
    border: 0;
    position: absolute;
    pointer-events: none;
  }
}

.great-text-input--search {
  position: relative;

  &::after {
    height: 35px;
    width: 51px;
    background-image: url(/static/images/search-black.svg);
    background-size: 20px;
    top: 4px;
    right: 2px;

    @include govuk-media-query($from: tablet) {
      background-size: 24px;
      height: 45px;
      width: 60px;
      top: 2px;
    }
  }
}

.great-text-select--arrow {
  position: relative;

  &::after {
    height: 39px;
    width: 31px;
    background-image: url(/static/icons/arrow-black.svg);
    background-size: 12px;
    top: 2px;
    right: 10px;
    transform: rotate(90deg);

    @include govuk-media-query($from: tablet) {
      background-size: 15px;
      top: 7px;
      right: 17px;
    }
  }
}

.great-text-select--arrow-max-500 {
  width: 200px;

  @include govuk-media-query($from: mobile) {
    width: 250px;
  }

  @include govuk-media-query($from: tablet) {
    width: 500px;
  }
}

.great-checkbox {
  .govuk-checkboxes__label:before {
    border-radius: 5px;
  }
}

.great-radios {
  .govuk-radios__label:before {
    background-color: $great-white;
  }
}

.great {
  select.great-select {
    appearance: none;
    background: $great-white;
    font-size: 16px;
    height: 44px;
    padding-left: 15px;
    width: 100%;

    &::placeholder {
      color: $great-dark-grey;
    }

    @include govuk-media-query($until: tablet) {
      padding-top: 8px;
    }

    @include govuk-media-query($from: tablet) {
      font-size: 18px;
      height: 50px;
    }
  }

  select.great-select--max-500 {
    width: 200px;

    @include govuk-media-query($from: mobile) {
      width: 250px;
    }

    @include govuk-media-query($from: tablet) {
      width: 500px;
    }

    option:first {
      color: $great-dark-grey;
    }
  }
}

.great-back-link {
  color: $color-main-text;
  position: relative;

  &:visited {
    color: $color-main-text;
  }

  &::before {
    box-sizing: initial;
    transform: rotate(135deg);
    content: '';
    border-style: solid;
    display: inline-block;
    height: 5px;
    width: 5px;
    position: relative;
    vertical-align: top;
    border-color: #333;
    border-width: 0 2px 2px 0;
    margin: 0px 10px 0 0px;
    top: 0.7em;

    @include govuk-media-query($from: tablet) {
      height: 6px;
      width: 6px;
      top: 0.6em;
    }
  }
}

.great-chevron-link {
  color: $govuk-brand-colour;
  padding-right: 20px;
  position: relative;

  &:visited {
    color: $govuk-brand-colour;
  }

  &::after {
    background-image: url(/static/icons/arrow.svg);
    background-size: 11px;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    display: inline-block;
    content: '';
    pointer-events: none;
    height: 20px;
    width: 12px;
    top: 2px;
    right: 0;
  }
}

.great-link--internal,
.great-link--external {
  position: relative;
  padding-right: 25px;

  &::after {
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    display: inline-block;
    content: '';
    pointer-events: none;
  }
}

.great-link--internal {
  &::after {
    background-image: url(/static/icons/internal-link.svg);
    background-size: 8px;
    height: 20px;
    width: 26px;
    bottom: 1px;
    right: 0;
  }
}

.great-link--external {
  &::after {
    background-image: url(/static/icons/external-link.svg);
    background-size: 14px;
    height: 21px;
    width: 16px;
    bottom: 1px;
    right: 0;
  }
}

.great-link--download {
  position: relative;
  padding-left: 25px;

  &::before {
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    display: inline-block;
    content: '';
    pointer-events: none;
    background-image: url(/static/icons/download.svg);
    background-size: 15px;
    height: 20px;
    width: 26px;
    top: 3px;
    left: 0;
  }

  &:focus {
    &::before {
      background-image: url(/static/icons/download-black-background.svg);
    }
  }
}

.great-heading-link {
  color: $govuk-brand-colour;
  display: block;
  position: relative;

  &::after {
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    display: inline-block;
    pointer-events: none;
    top: 12px;
    right: 0;
  }

  .great-heading-link__heading {
    color: $govuk-brand-colour;
    display: inline-block;
    text-decoration: underline;
    max-width: 80%;
    text-underline-position: under;

    --webkit-text-underline-offset: 0.05rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include govuk-media-query($from: tablet) {
      --webkit-text-underline-offset: 0.1rem;
    }
  }

  &:hover {
    color: #003078 !important;

    .great-heading-link__heading {
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
      color: #003078 !important;
    }
  }

  &:focus {
    outline: 0;
    color: #212427;

    .great-heading-link__heading {
      color: #212427;
      text-decoration: none;

      span {
        outline: 3px solid rgba(0, 0, 0, 0);
        color: #212427;
        background-color: govuk-colour('yellow');
        box-shadow: 0 -2px govuk-colour('yellow'), 0 4px #0b0c0c;
        text-decoration: none;
      }
    }
  }

  &:visited {
    .great-heading-link__heading {
      color: $govuk-link-visited-colour;
    }
  }

  &:active {
    color: #212427;
  }
}

.great-heading-link--no-chevron {
  &::after {
    content: '';
  }
}

.great-checkboxes-wrapper {
  max-height: 463px;
  overflow: auto;
  border-bottom: 2px solid $great-lighter-grey;
  margin-bottom: 20px;
  padding: 3px 0 0 3px;
}

.great-highlighted-text {
  background: $great-light-yellow;
  border-bottom: 3px solid black;
  display: inline-block;
  padding: 3px;
  margin: 0 5px 5px 0;
}

.great-details {
  .govuk-details__text {
    border-left-color: $great-blue;
    margin-top: 15px;
  }
}

.great-summary-list {
  position: relative;

  .govuk-summary-list__row {
    border-bottom: none;
  }

  .govuk-summary-list__key,
  .govuk-summary-list__value {
    @include govuk-media-query($until: tablet) {
      margin-bottom: 0;
    }
  }

  .govuk-link {
    position: absolute;
    top: 6px;
    right: 0;
  }

  h2 {
    @include govuk-media-query($until: tablet) {
      font-size: 20px;
      margin-bottom: 30px;
      max-width: 80%;
    }
  }
}

.great-icon-tick-in-circle {
  position: relative;
  background: #75c67c;
  border-radius: 50%;
  width: 45px;
  height: 45px;

  @include govuk-media-query($from: tablet) {
    width: 60px;
    height: 60px;
  }

  &::after {
    content: '';
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 12px;
    top: 21px;
    height: 19px;
    width: 7px;
    border-bottom: 5px solid $great-white;
    border-right: 5px solid $great-white;

    @include govuk-media-query($from: tablet) {
      left: 18px;
      top: 28px;
      height: 22px;
      width: 9px;
      border-bottom: 6px solid $great-white;
      border-right: 6px solid $great-white;
    }
  }
}

.great-icon-right-arrow-in-circle {
  content: '';
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $color-thematic-break;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-image: url(/static/icons/great-blue-arrow-right.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.great-notification-banner {
  border-color: $great-green;
  background-color: $great-green;
  margin-bottom: 20px;

  @include govuk-media-query($from: tablet) {
    margin-bottom: 30px;
  }

  .govuk-notification-banner__content {
    @include govuk-media-query($from: tablet) {
      padding: 40px 30px;
    }
  }
}

.govuk-notification-banner__content > * {
  box-sizing: border-box;
  max-width: none;
}

.great-tag {
  border-radius: 9999px;
  display: block;
  font-family: $great-font;
  max-width: 100px;
  text-align: center;
  background: $great-medium-blue;
  color: $great-white;
}

.great-tag--govuk {
  background: $great-darker-grey;
}

.great-tag--howTo {
  background: $great-purple;
}

.great-tag--tool {
  background: $great-red;
}

.great-tag--service {
  background: $great-dbt-green;
}

.great-topic-card {
  display: block;
  background: $great-white;
  text-decoration: none;
  width: calc(100%);

  @include govuk-media-query($from: tablet) {
    flex-basis: calc(33.333% - 30px);
  }

  &:focus:active {
    background: $great-white;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.great-topic-card--quarter-width {
  flex-basis: calc(25% - 30px);
  height: auto;
}

.great-topic-card--half-width {
  flex-basis: calc(50% - 30px);
  height: fit-content;

  @include govuk-media-query($from: desktop) {
    min-height: 270px;
  }
}

.great-topic-card--full-width {
  display: flex;
  position: relative;
  flex-basis: calc(100% - 30px);
  flex-direction: column;

  @include govuk-media-query($from: desktop) {
    flex-direction: row;
    min-height: 280px;
  }

  > div {
    flex-basis: 50%;
  }

  img {
    @include govuk-media-query($from: desktop) {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: calc(50% - 30px);
    }
  }
}

.great-card-wrapper {
  flex-wrap: wrap;

  @include govuk-media-query($from: desktop) {
    margin-right: -30px;
  }
}

.great-related-items {
  flex-wrap: wrap;
}

.great-related-item {
  flex-basis: calc(33.333% - 20px);

  @include govuk-media-query($from: desktop) {
    margin-right: 30px;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

.great-dep-triage-hero {
  position: relative;
  overflow: hidden;

  @include govuk-media-query($from: desktop) {
    min-height: 367px;
  }

  > div:first-of-type {
    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  p:last-of-type {
    margin-bottom: 30px;
  }

  img {
    display: none;
    bottom: 0;
    position: absolute;
    right: 0;

    @media screen and (min-width: 851px) {
      display: block;
      max-width: 600px;
    }

    @media screen and (min-width: 1251px) {
      max-width: 752px;
    }
  }
}

.great-dep-tools-and-services {
  h2 {
    @include govuk-media-query($until: tablet) {
      border: none;
    }
  }
}

.great-dep-card {
  a {
    text-decoration: none;
  }

  img {
    margin: 0;
    width: 100%;
    height: auto;
  }
}

.great-dep-homepage-title {
  position: relative;

  > div {
    @include govuk-media-query($until: tablet) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  img {
    @include govuk-media-query($until: tablet) {
      width: calc(100% + 30px);
      position: relative;
      left: -15px;
    }

    @include govuk-media-query($from: desktop) {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 50%;
    }
  }

  &__text {
    @include govuk-media-query($from: desktop) {
      flex-basis: 50%;
    }
  }
}

.great-dep-homepage-row {
  padding-bottom: 3px;

  .great-dep-card:first-of-type {
    @include govuk-media-query($until: tablet) {
      margin-bottom: 30px;
    }
  }
}

.great-primary-button--chevron {
  position: relative;
  padding-right: 25px;

  &::after {
    background-repeat: no-repeat;
    background-position: 50% 180%;
    display: inline-block;
    content: '';
    pointer-events: none;
    background-image: url(/static/icons/internal-link-white.svg);
    background-size: 12px;
    height: 21px;
    width: 26px;
    bottom: 1px;
    right: 0;
  }

  &:hover {
    &::after {
      background-image: url(/static/icons/internal-link.svg);
    }
  }
}

.great-secondary-button--chevron {
  position: relative;
  padding-right: 25px;

  &::after {
    background-repeat: no-repeat;
    background-position: 50% 180%;
    display: inline-block;
    content: '';
    pointer-events: none;
    background-image: url(/static/icons/internal-link.svg);
    background-size: 11px;
    height: 20px;
    width: 26px;
    bottom: 1px;
    right: 0;
  }

  &:hover {
    &::after {
      background-image: url(/static/icons/internal-link-white.svg);
    }
  }
}

nav.great-breadcrumbs {
  padding-top: calc(0.75em - (0.5 * $standard-padding)) !important;
  padding-bottom: calc(0.75em - (0.5 * $standard-padding)) !important;
  background-color: $color-background-color;

  ol {
    list-style-type: none;
    margin: 0;
  }

  li {
    padding: 0.5 * $standard-padding 0;
    margin-left: 0;
    margin-bottom: 0;
    display: inline-block;
    line-height: 1.25;
  }

  li::after {
    margin-right: $standard-padding;
    top: 0.4em;
  }

  li:first-child {
    background-image: none;
    margin-left: 0;
    padding-left: 0;
  }

  a,
  a:active:focus,
  span {
    font-size: 16px;
    color: $color-main-text;
  }

  @media only screen and (max-width: $mobile-size) {
    a {
      font-size: 14px !important;
    }

    li:not(:first-child, :last-child) {
      display: none;
    }
  }

  li {
    margin-bottom: 0;
  }
}

.great-breadcrumbs li:after {
  box-sizing: initial;
  transform: rotate(-45deg);
  content: '';
  border-style: solid;
  display: inline-block;
  height: 5px;
  width: 5px;
  position: relative;
  vertical-align: top;
  top: 1px;
  border-color: #333;
  border-width: 0 2px 2px 0;
  margin: 2px 0 0 10px;
  top: 0.25em;
}

.great-breadcrumbs ol li:last-child:after {
  display: none;
}

.great-list-blue-tick {
  list-style: none;

  > li {
    display: block;
    clear: left;
    padding: 7.5px 0;

    &:before {
      content: '';
      height: 30px;
      width: 30px;
      min-width: 30px;
      display: block;
      float: left;
      margin-left: 0;
      margin-right: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background: url('/static/icons/dark-blue-tick.svg');
      background-size: cover;
      background-position: center;
    }
  }
}

.great-list-black-tick {
  list-style: none;

  > li {
    display: flex;
    align-items: center;
    clear: left;
    padding: 0 0 20px;

    &:before {
      content: '';
      height: 30px;
      width: 30px;
      min-width: 30px;
      display: block;
      float: left;
      margin-left: 0;
      margin-right: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background: url('/static/icons/black-tick.svg');
      background-size: cover;
      background-position: center;

      @include govuk-media-query($from: tablet) {
        height: 40px;
        width: 40px;
        min-width: 40px;
      }
    }
  }
}

.great-link-button--chevron {
  svg {
    position: relative;
    top: 2px;
  }

  &:hover,
  &:focus {
    path {
      fill: $govuk-brand-colour;
    }
  }
}

.great-picture {
  img {
    display: block;

    @include govuk-media-query($until: tablet) {
      max-width: 100%;
    }
  }
}

.great-promo {
  display: flex;
  align-items: flex-end;

  @include govuk-media-query($until: tablet) {
    flex-direction: column-reverse;
  }

  img {
    @include govuk-media-query($until: tablet) {
      margin-bottom: 20px;
    }
  }
}

.great-step-item {
  z-index: 1;
  position: relative;
  display: flex;
  gap: 1rem;
  padding-bottom: 30px;

  &.fa {
    display: flex;
    font-weight: 400;
  }
  &::before {
    content: '';
    flex: 0 0 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #b1b4b6;
  }

  &.current-step {
    .govuk-link {
      color: $color-main-text !important;
    }
    &::before {
      background-color: #04043f;
      border-color: #04043f;
      content: '\f061';
      color: white;
      font-family: 'Font Awesome 5 Free';
      align-items: center;
      justify-content: center;
      display: flex;
      font-weight: 900;
    }
  }

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0px;
      z-index: -1;
      transform: translateX(13px);
      width: 2px;
      background-color: #b1b4b6;
    }
  }

  a:focus:active {
    color: inherit;
  }
}

@mixin child-link-states($selector) {
  &:hover {
    #{$selector} {
      text-decoration-line: underline;
    }
  }
  &:focus {
    #{$selector} {
      color: #212427;
      outline: 3px solid rgba(0, 0, 0, 0);
      background-color: #fd0;
      outline: none;
    }
  }

  &:active {
    #{$selector} {
      outline: 3px solid #fd0;
    }
  }

  &:focus:active {
    #{$selector} {
      background: $great-white;
      box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
      text-decoration: none;
      -webkit-box-decoration-break: clone;
      outline: 3px solid rgba(0, 0, 0, 0);
    }
  }
}

.great-arrow-link {
  @include child-link-states('.great-title-link');
}

.great-tag-container {
  display: flex !important;
  bottom: 8px !important;

  img {
    margin: 0px 8px 0px 0px !important;
  }
}

.great-share {
  border-top: 1px solid $govuk-border-colour;

  svg {
    margin-right: 10px;
  }

  path {
    fill: $great-dark-blue;
  }

  a {
    @include child-link-states('.govuk-link');
    display: inline-flex;
    align-items: center;

    &:hover {
      path {
        fill: $govuk-link-hover-colour;
      }
    }

    &:focus {
      margin-left: 50px;

      svg {
        left: -50px;
        position: relative;
      }

      path {
        fill: $govuk-focus-text-colour;
      }

      span {
        margin-left: -50px;
      }
    }
  }

  ul {
    @include govuk-media-query($from: tablet, $until: desktop) {
      display: flex;
    }

    li {
      @include govuk-media-query($from: tablet, $until: desktop) {
        margin-right: 30px;
      }
    }
  }

  button {
    &.small-button {
      min-width: 126px;
      min-height: 44px;
      height: fit-content;
      line-height: 25px;
      padding: 12px 15px;
      display: flex;
      align-items: center;
      .great-share-copy-link-container {
        margin: auto;
        > span {
          padding-right: 8px;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}

// Hero with input - Product/Market campaign

.great-hero--product-market {
  background-image: url(/static/images/hero-product-market-bg-1.png),
    url(/static/images/hero-product-market-bg-2.png);
  background-position: center top;
  overflow: hidden;
}

.great-hero--product-market,
.great-hero--product-market > .great-container {
  min-height: auto;
}

.great-hero--product-market {
  @media (min-width: 1170px) {
    min-height: 500px;
  }
}

.great-hero--product-market > .great-container {
  @include govuk-responsive-margin(9, 'top');
  @include govuk-responsive-margin(9, 'bottom');
}

.great-hero--product-market {
  .great-hero__heading {
    color: $great-white;
    margin-top: 0;
    font-size: 36px;
    line-height: 50px;

    @include govuk-media-query($from: tablet) {
      font-size: 44px;
      line-height: 60px;
    }

    @media (min-width: 1170px) {
      font-size: 80px;
      line-height: 85px;
    }
  }

  .great-hero__subtitle {
    font-size: 16px;
    line-height: 20px;
    color: $great-white;
    margin: 10px 0 20px;

    @include govuk-media-query($from: tablet) {
      font-size: 20px;
      line-height: 30px;
      margin: 20px 0;
    }
  }

  .great-hero__container {
    @include govuk-media-query($from: desktop) {
      display: flex;
    }
  }

  .great-hero__text {
    @include govuk-media-query($from: tablet, $until: desktop) {
      width: 90%;
    }
  }

  .great-hero__search {
    margin-top: 15px;

    @include govuk-media-query($from: tablet) {
      align-self: center;
    }

    @media (min-width: 1170px) {
      margin-top: 0;
    }
  }

  .great-hero__form {
    @include govuk-media-query($from: tablet) {
      margin-left: 20px;
    }
  }

  .great-hero__form-inputs {
    display: flex;
    flex-direction: column;

    @media (min-width: 1170px) {
      flex-direction: row;
    }
  }

  .great-hero__label {
    font-size: 24px;
    color: $great-white;
    font-weight: bold;
    display: block;
    @include govuk-responsive-margin(5, 'bottom');

    @include govuk-media-query($from: tablet) {
      font-size: 28px;
    }
  }

  .great-hero__input {
    height: 53px;
    padding: 0 1rem;
    border-color: $great-white !important;
    margin-bottom: 15px;

    &:focus {
      border-color: $great-dark-blue !important;
    }

    @media (min-width: 1170px) {
      flex-grow: 2;
      flex-basis: 0;
      margin-right: 10px;
    }
  }

  .great-hero__button {
    height: 53px;
    margin-bottom: 15px;

    @media (min-width: 1170px) {
      flex-grow: 1;
      flex-basis: 0;
    }

    &::after {
      position: relative;
      background-position: 50% 50%;
      height: 16px;
      width: 10px;
      bottom: -2px;
      right: -10px;
    }
  }

  .great-hero__button-text {
    margin-left: -10px;
  }

  .great-hero__link {
    color: $great-white;
    display: inline-block;

    @include govuk-media-query($from: tablet) {
      margin-left: 20px;
    }
  }

  .great-hero__logo {
    width: 260px;
    position: absolute;
    right: 0;
    bottom: 100px;
    display: none;

    img {
      width: 100%;
    }

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1025px) {
      width: 412px;
    }
  }

  .great {
    overflow-x: visible;
  }
}

.great-hero--flight-path {
  background-color: $great-dark-blue;
  background-image: none;

  @include govuk-media-query($from: desktop) {
    background-color: $great-dark-blue;
    background-image: url(/static/images/Great-homepage-hero-domestic.png);
  }

  .great-hero__heading {
    mark {
      display: inline;
      background: $great-dark-blue;
      color: $great-white;

      @include govuk-media-query($from: desktop) {
        display: inline-block;
        padding-right: 40px;
      }

      &:first-of-type {
        position: relative;
      }

      &:last-of-type {
        @include govuk-media-query($from: desktop) {
          padding-bottom: 10px;
        }
      }
    }
  }

  .great-hero__subtitle {
    display: inline-block;
    background: $great-dark-blue;
    padding: 25px 40px 20px 0;
    margin-top: 0;
    margin-bottom: 10px;
    max-width: 700px;
  }
}

// Product/Market campaign page

.great-product-market {
  background: $great-white;
}

.great-product-market__product-label {
  color: $great-dark-blue;
  font-weight: bold;
  margin-right: 30px;
}

.great-product-market__form-inputs {
  display: flex;
  flex-direction: column;
  @include govuk-media-query($from: tablet) {
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }
}

.great-product-market__form-inputs input {
  height: 53px;
  padding: 0 1rem;
}

.great-product-market__button-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
    align-items: baseline;
    text-align: left;
  }
}

.great-product-market__button {
  height: 53px;
  margin: 15px 0;
  width: auto;

  @include govuk-media-query($from: tablet) {
    margin: 15px 20px 15px 0;
  }

  &::after {
    position: relative;
    background-position: 50% 50%;
    height: 16px;
    width: 10px;
    bottom: -2px;
    right: -10px;
  }
}

.great-product-market__cards {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 50px;

  @include govuk-media-query($from: tablet) {
    flex-direction: row;

    > div {
      margin-right: 30px;
    }
  }

  .great-card {
    flex: 1;
  }
}

.great-inline-feedback__container {
  border-top-color: #dde5ed;
  border-top-style: solid;
  border-top-width: 2px;
  background-color: $great-white;
}

.great-inline-feedback__page_useful_form {
  display: flex;
  gap: govuk-spacing(4);
  align-items: center;

  button {
    margin: 0px !important;
    min-width: auto;
    width: auto;
  }
}

.great-inline-feedback__submission-confirmation,
.csat-submission-confirmation__heading {
  display: flex;
  gap: govuk-spacing(2);

  span[role='img'] {
    font-size: 28px;
    color: #00703c;
  }
}

.great-inline-feedback__submission-error {
  display: flex;
  gap: govuk-spacing(2);
  padding-bottom: govuk-spacing(6);

  span[role='img'] {
    font-size: 28px;
    color: #d4351c;
  }
}

.great-inline-feedback__detail-form {
  padding-top: govuk-spacing(6);

  p {
    margin: 0;
    color: #505a5f;
  }
}

.great-inline-feedback-detail-form__submission_buttons {
  display: flex;
  gap: 30px;
  align-items: center;

  button {
    margin-top: 0px !important;
  }
}

.great-checkbox--inline-feedback {
  label {
    font-family: $main-text-font;
    font-size: 18px;
  }

  [type='checkbox'] + label::before {
    border: 2px solid #222222;
    border-radius: 4px;
  }
}

.js-enabled .govuk-checkboxes__conditional--hidden {
  display: none;
}

.great-cookie-notice {
  display: none;
  padding: 30px 0 0;

  &.block {
    display: block;
  }
}

.great-modal {
  position: fixed;
  inset: 0px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.great-modal__content {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  max-width: 800px;
  bottom: auto;
  right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 30px);
  width: calc(100vw - 30px);

  button {
    @include govuk-media-query($from: tablet) {
      margin: 0;
    }

    &:first-of-type {
      @include govuk-media-query($from: tablet) {
        margin-right: 10px;
      }
    }
  }
}

.great-accordian-sector-link {
  text-decoration: none;
  color: $great-blue;
  font-weight: 700;
  position: relative;
  display: flex;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
  margin-bottom: 0;
  align-items: center;
  min-height: 88px;

  &:after {
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    color: $color-gov-link;
    font-weight: 600;
    right: 15px;
  }
}

.great-notification-banner--error {
  margin: 0 auto;
  max-width: 810px;
}

.great-meta-label {
  color: $great-grey;
  display: flex;
  font-family: $great-font;
  font-weight: 100;
  gap: 10px;
  font-size: 16px;
}
