$great-white: #fff;
$great-blue: #006ccc;
$great-dark-blue: #04043f;
$great-medium-blue: #5e9be8;
$great-light-blue: #eef2f6;
$great-darker-grey: #646464;
$great-text-dark-grey: #505a5f;
$great-grey: #666;
$great-light-grey: #7c7c7c;
$great-lighter-grey: #eee;
$great-dark-grey: #212427;
$great-darker-grey: #222;
$great-yellow: #ffbf47;
$great-light-yellow: #f1f77a;
$great-hr-blue: #d6e0e5;
$great-teal: #0d8d98;
$great-green: #397e44;
$great-dbt-green: #004d44;
$great-purple: #964f99;
$great-red: #f36565;
$great-dark-red: #eb0000;
$great-off-black: #0b0c0c;
$great-border-grey: #b1b4b6;
