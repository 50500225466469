$color-error: #d4351c;
.great {
  *:lang(ar) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .govuk-button,
    .great-font-bold,
    strong,
    .govuk-form-group-error .error-message ul li {
      font-family: $arabic-header-text-font;
    }

    p:not(.govuk-error-message),
    span:not(.great-badge, .great-text-darker-grey, .govuk-details__summary-text),
    ol,
    ul,
    a,
    .govuk-hint,
    .govuk-error-message,
    .govuk-radios__label,
    .govuk-details,
    .govuk-link,
    .govuk-form-control,
    em {
      font-family: $arabic-main-text-font;
    }
  }

  *:lang(ko) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .govuk-button,
    .great-font-bold,
    strong,
    .govuk-form-group-error .error-message ul li {
      font-family: $korean-header-text-font;
    }

    p:not(.govuk-error-message),
    span:not(.great-badge, .great-text-darker-grey, .govuk-details__summary-text),
    ol,
    ul,
    a,
    .govuk-hint,
    .govuk-error-message,
    .govuk-radios__label,
    .govuk-details,
    .govuk-link,
    .govuk-form-control,
    em {
      font-family: $korean-main-text-font;
    }

    &:dir(rtl) {
      .govuk-grid-column-two-thirds {
        float: right !important;
      }
    }
  }

  a {
    &[lang='ar'] {
      font-family: $arabic-main-text-font;
    }
    &[lang='ko'] {
      font-family: $korean-main-text-font;
    }
  }
}

*[dir='rtl'] {
  .govuk-grid-column-two-thirds,
  .govuk-grid-column-three-quarters,
  .govuk-grid-column-one-quarter,
  .govuk-grid-column-one-third {
    float: right;
  }

  .govuk-form-group-error {
    padding-left: 0 !important;
    padding-right: 15px;
    border-left: none !important;
    border-right: 5px solid $color-error;
  }
}

.microsite *:lang(ar) .govuk-form-label {
  font-family: $arabic-header-text-font;
}

.microsite *:lang(ko) .govuk-form-label {
  font-family: $korean-header-text-font;
}

*:lang(ar) {
  .great-inline-feedback__container {
    display: none;
  }

  .footer,
  footer div.footer ul,
  .copyright-container .copy-right {
    direction: ltr;
    padding: 0;
  }
}
