@import '../../../styles/sizes/sizes.scss';

.sector-potential-section {

  & p{
    font-size:20px
  }

  .view-markets {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 20px;
  }

  @media (min-width: 1081px) {
    .sector-potential-wrapper {}
  }

  @media (min-width: 641px) {
    .view-markets {
      width: auto;
    }
  }


}

.sector-potential-form {
  display: inline-flex;
  flex-wrap: wrap;
  gap: $standard-padding;

  &>button {
    margin-top: 0;
    height: 50px;
    @media only screen and (max-width: $mobile-size) {
 width: 100%;
}
  }
}

#id_sector-container {
  width: 436px;

  @media only screen and (max-width: $mobile-size) {
  width: 100%;
}

  label {
    @extend .verbose
  }
}

select#id_sector{
    width: 100%;
    height: 50px;
    background-color: $white;
  }

@media (min-width: 641px) {
  .view-markets {
    width: auto;
  }

  select {
    max-width: 650px;
  }
}

@media (min-width: 961px) {
  select {
    max-width: 770px;
  }
}

.sector-selector-quick-links {

  & p{
    font-size: 20px;
  }
  & span{
    font-size: 20px;
  }
  .quick-links-separator {
    margin: 0 0 45px 0;
    width:100%!important;
    height: 4px;
    background-color: #D6E0E5;
  }

  li {
    display: none;
    text-align: center;
    float: left;
  }

  .quick-link {
    text-decoration: none;
  }

  .sector-name {
    font-weight: 600;
    text-decoration: underline;
  }

  .quick-links-icon {
    height: 60px;
    display: block;
    margin: 0 auto 15px;
  }

  .sector-name,
  .sector-page-count {
    color: #1D70B8;
  }

  @media (max-width: 640px) {
    display: none;
  }

  @media (min-width: 641px) {
    li:nth-child(-n+3) {
      display: block;
      width: calc(100% / 3);
    }
  }

  @media (min-width: 769px) {
    li:nth-child(-n+4) {
      display: block;
      width: calc(100% / 4);
    }
  }

  @media (min-width: 961px) {
    li:nth-child(-n+6) {
      display: block;
      width: 16.66%; // ie edge fix
    }
  }

  @media (min-width: 1081px) {
    .quick-links-separator {
      width: 60%;
    }
  }
}
