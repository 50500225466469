$gds-base-spacing-unit: 15px;
$design-max-width: 1200px;

// SPACING
$spacing-1: 60px;
$spacing-2: 45px;
$spacing-3: 30px;
$spacing-4: 15px;

// and reduced for Mobile
$spacing-1-m: 40px;
$spacing-2-m: 30px;
$spacing-3-m: 20px;
$spacing-4-m: 10px;
