@import '../colors/colors.scss';
@import '../sizes/sizes.scss';
@import './typography.scss';
@import './foreign-language-typography.scss';
@import './icons.scss';
@import '../sizes/sizes.scss';

.page-title {
  padding: $x-large-padding 0 $large-padding 0;
}

.link {
  color: $color-gov-link;
}

.learning-link:visited,
.link:hover,
.link:active,
.link:focus:active {
  color: $color-visited;
}

article > h5 {
  padding-top: $standard-padding;
}

article > p {
  margin: 0;
  padding-bottom: $standard-padding;
}

.subheading  {
@include p-mixin
}

.big-number-list li:before {
  font-family: $header-font !important;
  color: $color-main-text !important;
  font-weight: $font-weight-semi-bold;
}

//just overidde colors
.h-m,
.h-s,
a.learn__category-link,
.body-m,
.body-l,
.h-l,
.body-l-b,
.h-xs,
.body-l,
.body-s,
div,
label.body-l,
.body-m-b,
.body-s-b,
.heading-large,
.heading-xlarge,
.heading-small,
.heading-medium,
.multiple-choice label,
.select__button i,
.great-checkbox label,
.great-checkbox label::before,
.learn__lesson-item-link:visited,
.learning-link:visited {
  color: $color-main-text;
}

//override font-family
.h-m,
.h-s,
.h-l,
.h-xs,
.heading-large,
.heading-xlarge,
.heading-small,
.heading-medium,
p.lede,
.results.title,
#search-results-information p,
.heading-small{
  font-family: $header-font;
  font-weight: $font-weight-semi-bold;
}

.link,
.form-control,
.body-m,
.body-s,
.body-l,
.great-checkbox label,
.multiple-choice label,
.font-xsmall,
.subheading {
  font-family: $main-text-font !important;
  font-weight: $regular-font-weight;
}

.body-l-b {
  font-size: $font-size-p;
}

.magna-header__menu-items button,
.magna-header__search input,
select.form-control,
.breadcrumbs li {
  font-size: $font-size-button;
  font-family: $main-text-font;
}

.bold,
.bold-large,
.bold-medium,
.bold-small,
.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.block-text:not(.microsite .block-text) h1,
.block-text:not(.microsite .block-text) h2,
.block-text:not(.microsite .block-text) h3,
.block-text:not(.microsite .block-text) h4,
#search-results-information p,
.body-l-b,
.card .card-inner h3,
a.labelled-card .card-inner h3,
a.labelled-image-card .card-inner h3,
.card .card-inner h4,
a.labelled-card .card-inner h4,
a.labelled-image-card .card-inner h4,
.card .card-inner h5,
a.labelled-card .card-inner h5,
a.labelled-image-card .card-inner h5,
.card .card-inner h6,
a.labelled-card .card-inner h6,
a.labelled-image-card .card-inner h6 {
  font-weight: 600;
}

.rich-text i {
  font-size: inherit;
}


.article-list-page ul.content-list li h3{
 @include h3-mixin;
}

.rich-text h1{
  @include h1-mixin
}

.heading-xlarge,
.govuk-heading-xlarge,
.rich-text h1 {
  @include h1-mixin;
}

.rich-text h2{
  @include h2-mixin
}

.rich-text h3{
  @include h3-mixin
}

.rich-text h4{
  @include h4-mixin
}

.exred-triage-form .heading-medium{
  font-family: $header-font!important;
}
