
// CAMPAIGN PAGE
// =============

@import '../partials/colours';

.column-11-12 {
  width: calc(100% / 12 * 11);
}

.campaign-page {

  .selling-points-list {
    .heading {
      margin-top: 10px;
    }
  }

  .related-content {
    img {
      width: 100%;
    }
  }
}

@media (max-width: 640px) {
  .section-two-image {
    margin: 0 0 15px 0;

    img {
      width: 100%;
    }
  }
}
