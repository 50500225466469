
// TAG LIST PAGE
// ==============

.tag-list-page {

  .hero .tag-name {
    display: block;
  }

}
