.barchart td,
.barchart th {
  font-size: 1rem;
  border: 0;
}

.barchart__legend {
  padding: 0;
  font-weight: normal;
}

.barchart__legend:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  vertical-align: text-top;
  background: #006ccc;
}

.barchart__title-cell {
  padding: 0 .5em 0 0;
  width: 40%;
  text-align: right;
  vertical-align: middle;
}

.barchart__bar-cell {
  padding: 3px 0;
  vertical-align: middle;
}

.barchart__bar {
  display: inline-block;
  padding: .5em 0;
  background: #006ccc;
  color: white;
  text-shadow: 1px 1px 0 #006ccc;
  text-indent: .5em;
}

.barchart__bar--small {
  color: currentColor;
  text-shadow: none;
  text-indent: calc(.375em + 100%);
}
