// GOVUK styles with Great styles
// All classes are prefixed with either
// .govuk- or .great-
@import 'styles/great/main.scss';

// Config
$asset-root: '/static/images/';
$fonts: '/static/fonts';
$fa-font-path: '/static/fonts';

// Icons
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

// PARTIALS
// from core/sass
@import 'partials/colours';
@import 'partials/vars';
@import 'partials/typography';
@import 'partials/layout';
@import 'partials/mixins';
@import 'partials/utils';

// Great styles
@import 'overrides/magna';
@import 'node_modules/great-styles/src/scss/components/header-footer/header';
@import 'node_modules/great-styles/src/scss/elements/utils';
@import 'node_modules/great-styles/src/scss/elements/tags';

// COMPONENTS
@import 'components/modal';
@import 'components/reveal';
@import 'components/language-selector-home';
@import 'components/eu-exit-banner';
@import 'components/pagination';
@import 'components/utils';
@import 'components/sector-selector-form';
@import 'components/chevron-banner';
@import 'components/filters';
@import 'components/tabs';
@import 'components/bar-chart';
@import 'components/table';

// PAGES
@import 'pages/landing-page';
@import 'pages/article-list';
@import 'pages/tag-list';
@import 'pages/article-detail';
@import 'pages/campaign-page';
@import 'pages/country-guide-page';
@import 'pages/markets-landing';

#content {
  padding: 0;
  color: $grey;
}
