@import '../partials/vars';
@import '../partials/mixins';
@import '../../../styles/colors/colors.scss';

.accordion-divider {
  border-top: 1px solid $light-grey;

  &:last-child {
    border-bottom: 1px solid $light-grey;
  }
}

.accordion-expander {
  text-decoration: none;
  color: $active-blue !important;
  font-weight: 700;
  position: relative;
  display: flex;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
  align-items: center;
  min-height: 88px;
  cursor: pointer;

  &:focus {
    @include focus;
  }

  &:after {
    content: '+';
    font-weight: 700;
    font-size: 36px;
    position: absolute;
    right: $spacing-4;
    color: $color-gov-link;
  }

  &[aria-expanded="true"]:after {
    content: '-';
  }
}

.accordion-content[aria-hidden="true"] {
  display: none;
}

.accordion-icon {
  height: 60px;
  width: 60px;
}

.accordion-content {
  .with-arrow {
    margin: 0;

    &:before {
      display: none;
    }
    &:after {
      content: '➔';
      margin-left: 0;
    }
  }

  .subsection-icon {
    height: 50px;
  }
}
