// GENERAL SITE STYLES
// ---------------------
@import '../../../styles/sizes/sizes.scss';

#menu-activator {
  margin-top: 0;
}

a:focus {
  outline: $focus-yellow solid 3px;
}

button.link {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

section {
  clear: both;
  padding-bottom: 90px;

  .intro {
    @include columns(6);
    clear: left;
    margin-bottom: $spacing-2;
  }
}

table {
  margin-bottom: 2em;
}

.container {
  max-width: $content-max-width;
  padding: 0 auto;
  margin: auto;

  @media (max-width: #{$content-max-width + ($spacing-3 * 2)}) {
    padding: 0 $spacing-3;
  }

  @media (max-width: $mobile-size) {
    padding: 0 15px; // Special figure intentionally outside of standard.
  }
}

.group {
  // Use to clear a group of floated elements
  // and have the parent recalculate its height.
  clear: both;
  float: none;
}

.verbose {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#content .hidden {
  display: none;
}
