.autocomplete__wrapper .great-autocomplete-overlay {
  width: 100%;
  max-height: 144px;

  @media only screen and (min-width: 900px) {
    max-height: 375px;
  }

  .autocomplete__option {
    &:hover,
    &:focus {
      span {
        color: white;
      }
    }
  }
}

.autocomplete__menu li:not(.SelectiveLookupDisplay__list-item--inactive):hover,
.autocomplete__menu li:not(.SelectiveLookupDisplay__list-item--inactive):focus,
.SelectiveLookupDisplay
  li:not(.SelectiveLookupDisplay__list-item--inactive):hover,
.SelectiveLookupDisplay
  li:not(.SelectiveLookupDisplay__list-item--inactive):focus {
  background-color: #1d70b8 !important;
  color: #fff;
}
